import Vue from 'vue';
import VueI18n from 'vue-i18n';
const locale2 = require('locale2');

Vue.use(VueI18n);

const messages = {
  en: {
    countries: require('@/assets/countries.json'),
    menu: {
      dashboard: 'Dashboard',
      rapidInsight: 'Insight',
      testing: 'Testing',
      validate: 'Validate',
      verify: 'Verify',
      pulseNav: 'MODIOS Pulse',
      pma: 'Product Mapping Accelerator',
    },
    modal: {
      create: {
        success: '{type} successfully created',
      },
      edit: {
        success: '{type} successfully updated',
      },
    },
    button: {
      save: 'Save',
      create: 'Create',
      cancel: 'Cancel',
      delete: 'Delete',
      testConnection: 'Test Connection',
      details: 'View Details',
    },
    regvalStatus: {
      PENDING: 'Pending',
      INVALID_INPUT: 'Invalid Input',
      IN_PROGRESS: 'In Progress',
      VALID: 'Valid',
      INVALID_FORMAT: 'Invalid Format',
      NOT_FOUND: 'Not Found',
      NAME_MISMATCH: 'Name Mismatch',
      UNSUPPORTED: 'Unsupported',
      COMPLETED: 'Completed',
    },
    regvalTaxpayerType: {
      CUSTOMER: 'Customer',
      VENDOR: 'Vendor',
      UNKNOWN: '--',
      TAXPAYER: 'Taxpayer',
      OTHER: 'Other',
    },
    entity: {
      taxEngineConnection: 'Tax Engine Connection|Tax Engine Connections',
      line: 'Line|Lines',
      testDeck: 'Test Deck|Test Decks',
      testDocument: 'Test Document|Test Documents',
      registration: 'Registration|Registrations',
    },
    taxEngineType: {
      SBX: 'ONESOURCE',
      VTX: 'Vertex',
      AVA: 'Avalara',
      SVO: 'Sovos',
      CRD: 'Cordiance',
      NONE: '',
      UNKNOWN: '',
    },
    taxabilityType: {
      ZERO: 'Zero',
      Z: 'Zero',
      NONZERO: 'Non-Zero',
      NON_ZERO: 'Non-Zero',
      NZ: 'Non-Zero',
      PARTZERO: 'Part Zero',
      PZ: 'Part Zero',
      C_ZERO: 'Country Zero',
      P_ZERO: 'Division Zero',
      L_ZERO: 'Local Zero',
      RC: 'Reverse Charge',
    },
    nextGenTaxabilityType: {
      ZERO: 'Zero',
      Z: 'Zero',
      NON_ZERO: 'Non-Zero',
      NONZERO: 'Non-Zero',
      NZ: 'Non-Zero',
      P_ZERO: 'Regional Zero',
      PZ: 'Regional Zero',
      C_ZERO: 'Country Zero',
      CZ: 'Country Zero',
      L_ZERO: 'Local Zero',
      LZ: 'Local Zero',
      RC: 'Reverse Charge',
    },
    companyRole: {
      S: 'Seller',
      SELLER: 'Seller',
      B: 'Buyer',
      BUYER: 'Buyer',
      M: 'Middleman',
      MIDDLEMAN: 'Middleman',
    },
    transactionType: {
      AT: 'Ancillary Transport Services',
      BG: 'India SEZ - Buyer Goods',
      BS: 'India SEZ - Buyer Services',
      CL: 'Customer Location Services',
      DS: 'Default Services',
      ES: 'Electronic Services',
      GS: 'Goods',
      IN: 'Installation Services',
      MV: 'Movement',
      PC: 'Prime Contracting',
      PP: 'Physical Performance',
      RR: 'Rental',
      SG: 'India SEZ - Seller Goods',
      SI: 'Supplied and Installed',
      SS: 'India SEZ - Seller Services',
      T1: 'Chain Transaction 1',
      T2: 'Chain Transaction 2',
      TG: 'Telecommunication Services - General',
      TP: 'Telecommunication Services - Private',
      TS: 'Transport Services',
      WG: 'Work on Goods',
      SALE: 'Sale',
      PURCHASE: 'Purchase',
    },
    unitOfMeasure: {
      '4G': 'Microliter',
      '4H': 'Micrometer',
      '5I': 'Standard cubic foot',
      ACR: 'Acre',
      CLT: 'Centiliter',
      CMK: 'Square centimeter',
      CMQ: 'Cubic centimeter',
      CMT: 'Centimeter',
      DMQ: 'Cubic decimeter',
      DMT: 'Decimeter',
      FOT: 'Foot',
      FTK: 'Square foot',
      FTQ: 'Cubic foot',
      GLL: 'US Gallon',
      GRM: 'Gram',
      INH: 'Inch',
      INK: 'Square inch',
      INQ: 'Cubic inch',
      KGM: 'Kilogram',
      KMK: 'Square kilometer',
      KMT: 'Kilometer',
      LBR: 'Pound',
      LTR: 'Liter',
      MGM: 'Milligram',
      MIK: 'Square mile',
      MLT: 'Milliliter',
      MMK: 'Square millimeter',
      MMQ: 'Cubic millimeter',
      MMT: 'Millimeter',
      MTK: 'Square meter',
      MTQ: 'Cubic meter',
      MTR: 'Meter',
      ONZ: 'Ounce',
      OZA: 'Fluid ounce (US)',
      PT: 'Pint (US liquid)',
      QT: 'Quart (US liquid)',
      SMI: 'Mile',
      STN: 'US Ton',
      TNE: 'Tonne (1000 kg)',
      YDK: 'Square yard',
      YDQ: 'Cubic yard',
      YRD: 'Yard',
    },
    poTT: {
      I: 'In Transit',
      IN_TRANSIT: 'In Transit',
      D: 'Destination',
      DESTINATION: 'Destination',
      O: 'Origin',
      ORIGIN: 'Origin',
    },
    testRecordStatus: {
      VALID: 'Valid',
      INVALID: 'Failed',
      FAILED: 'Error',
    },
    testRunStatus: {
      PENDING: 'Pending',
      IN_PROGRESS: 'In Progress',
      RETRIEVING: 'Processing Results',
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      FAILED: 'Failed',
    },
    addressType: {
      bill_to: 'Bill To',
      BILL_TO: 'Bill To',
      buyer_primary: 'Buyer Primary',
      BUYER_PRIMARY: 'Buyer Primary',
      middleman: 'Middleman',
      MIDDLEMAN: 'Middleman',
      order_origin: 'Order Origin',
      ORDER_ORIGIN: 'Order Origin',
      order_acceptance: 'Order Acceptance',
      ORDER_ACCEPTANCE: 'Order Acceptance',
      seller_primary: 'Seller Primary',
      SELLER_PRIMARY: 'Seller Primary',
      ship_from: 'Ship From',
      SHIP_FROM: 'Ship From',
      ship_to: 'Ship To',
      SHIP_TO: 'Ship To',
      supply: 'Supply',
      SUPPLY: 'Supply',
      LINE_DEST: 'Destination',
      LINE_ADMIN_DEST: 'Admin Destination',
      LINE_PHYS_ORIGIN: 'Physical Origin',
      LINE_ADMIN_ORIGIN: 'Admin Origin',
      DOC_DEST: 'Destination',
      DOC_ADMIN_DEST: 'Admin Destination',
      DOC_PHYS_ORIGIN: 'Physical Origin',
      DOC_ADMIN_ORIGIN: 'Admin Origin',
      GOODS_PLACE_OR_SERVICE_RENDERED: 'Place/Service Rendered',
    },
    address: {
      division: {
        main_division: 'Main Division',
        state: 'State',
        province: 'Province',
        region: 'Region',
      },
    },
    counterParty: {
      CUSTOMER: 'Customer',
      VENDOR: 'Vendor',
    },
    productType: {
      PRODUCT_CODE: 'Product Code',
      COMMODITY_CODE: 'Commodity Code',
      PART_NUMBER: 'Part Number',
      SKU: 'SKU',
      PRODUCT_CLASS: 'Product Class',
      COMMODITY_CODE_TYPE: 'Commodity Code Type',
      MATERIAL_CODE: 'Material Code',
      PURCHASE: 'Purchase',
      PURCHASE_CLASS: 'Purchase Class',
    },
    jurisdictionLevelCode: {
      APO: 'APO/DPO',
      BOROUGH: 'Borough',
      CITY: 'City',
      COUNTRY: 'Country',
      COUNTY: 'County',
      DISTRICT: 'District',
      FPO: 'Field Post Office',
      LOCAL_IMPROVEMENT_DISTRICT: 'Local Improvement Dist.',
      PARISH: 'Parish',
      PROVINCE: 'Province',
      SPECIAL_PURPOSE_DISTRICT: 'Special Purpose Dist.',
      STATE: 'State',
      TERRITORY: 'Territory',
      TOWNSHIP: 'Township',
      TRADE_BLOCK: 'Trade Block',
      TRANSIT_DISTRICT: 'Transit Dist.',
    },
    taxResultCode: {
      TAXABLE: 'Taxable',
      NONTAXABLE: 'Non-Taxable',
      EXEMPT: 'Exempt',
      DPPAPPLIED: 'Direct Pay',
      NO_TAX: 'No Tax',
      DEFERRED: 'Deferred',
    },
    taxingType: {
      SALES: 'Sales Tax',
      SELLER_USE: "Seller's Use",
      CONSUMERS_USE: "Consumer's Use",
      VAT: 'VAT',
      IMPORT_VAT: 'Import VAT',
      NONE: 'None',
    },
    taxingLocationType: {
      ADMINISTRATIVE_DESTINATION: 'Admin. Dest.',
      ADMINISTRATIVE_ORIGIN: 'Admin. Origin',
      DESTINATION: 'Destination',
      PHYSICAL_ORIGIN: 'Phys. Orign',
    },
    inputOutputCodeType: {
      INPUT: 'Input',
      IMPORT: 'Import',
      OUTPUT: 'Output',
      INPUT_OUTPUT: 'Reverse Charge',
    },
    taxStructureCodeType: {
      BRACKET: 'Bracket',
      FLAT_TAX: 'Flat Tax',
      QUANTITY: 'Quantity',
      SINGLE_RATE: 'Single Rate',
      TIERED: 'Tiered',
      QUANTITY_TIERED: 'Qty. Tiered',
      QUANTITY_RATE_TIERED: 'Qty. Rate Tiered',
      TIERED_MODIFIER: 'Tiered Modifier',
      QUANTITY_TIERED_MODIFIER: 'Qty. Tiered Mod.',
      QUANTITY_RATE_TIERED_MODIFIER: 'Qty. Rate Tiered Mod.',
      QUANTITY_TIERED_FLAT: 'Qty. Tiered Flat',
      TIERED_FLAT: 'Tiered Flat',
      QUANTITY_RATE_TIERED_EQUIVALENT: 'Qty. Rate Tiered Equiv.',
    },
    avaDocType: {
      PURCHASE: 'Purchase',
      PurchaseOrder: 'Purchase',
      PurchaseInvoice: 'Purchase (Inv.)',
      SALES: 'Sale',
      SalesOrder: 'Sale',
      SalesInvoice: 'Sale (Inv.)',
      RETURN: 'Return',
      ReturnOrder: 'Return',
      ReturnInvoice: 'Return (Inv.)',
      INV_XFER: 'Inventory Transfer',
      InventoryTransferOrder: 'Inventory Transfer',
      InventoryTransferInvoice: 'Inventory Transfer (Inv.)',
      REV_CHARGE: 'Reverse Charge',
      ReverseChargeOrder: 'Reverse Charge',
      ReverseChargeInvoice: 'Reverse Charge (Inv.)',
      CUSTOMS: 'Customs',
      CustomsOrder: 'Customs',
      CustomsInvoice: 'Customs (Inv.)',
    },
  },
};

export default new VueI18n({
  locale: getBrowserLocale(),
  fallbackLocale: 'en',
  messages,
});

function getBrowserLocale() {
  try {
    return locale2?.trim()?.split(/-|_/)[0];
  } catch (err) {
    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (!navigatorLocale) {
      return undefined;
    }

    return navigatorLocale.trim().split(/-|_/)[0];
  }
}
